import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

import '@bluecodecom/fonts';

import './i18n';

const App = lazy(() => import('./app'));

declare global {
  interface Window {
    jwt?: string;
  }
}

createRoot(document.getElementById('root') as HTMLElement).render(<App />);
