const de = {
  close: 'Schließen',
  update: {
    title: 'Aktualisiere deine Bluecode App',
    description:
      'Aktualisiere bitte deine Bluecode App über den App Store oder Google Play Store auf die neuste Version, um fortzufahren. Danach scanne den QR-Code erneut und folge den Zahlungsschritten.',
  },
  cancel: {
    title: 'Zahlung abgebrochen',
    description: 'Die Zahlung wurde nicht durch geführt.',
  },
  error: {
    title: 'Leider ist ein Fehler aufgetreten.',
    description:
      'Bitte versuche es erneut. Tippe unten auf "Schließen" und scanne den QR-Code erneut.',
  },
  header: {
    subtitle: { payment: 'QR-Code-Zahlung', donation: 'QR-Code-Spende' },
  },
  extraItems: {
    title: 'Oder wähle eines aus dieser Liste',
  },
  'no-bank-account': {
    title: 'Kein Bankkonto verbunden',
    description:
      'Verbinde jetzt schnell und einfach dein Bankkonto, um zu bezahlen.',
    button: 'Bankkonto verbinden',
  },
  tip: {
    total: 'Rechnungsbetrag: {{amount}}',
    custom: {
      title: 'Gib den aufgerundeten Betrag ein',
      input: 'Neuer Gesamtbetrag',
      confirm: 'Bezahlen',
      errors: {
        amount: 'Bitte den Gesamtbetrag eingeben, inkludiert Trinkgeld.',
      },
    },
    percentage: {
      title: 'Möchtest du Trinkgeld geben?',
      items: {
        title: 'Ja, aufrunden auf:',
      },
      custom: 'Eigenen Betrag eingeben',
      cancel: 'Ich will kein Trinkgeld geben',
      confirm: 'Bezahlen {{amount}}',
    },
  },
  new: {
    input: 'Betrag eingeben',
    payment: {
      title: {
        custom: 'Gib den Gesamtbetrag ein.',
        fixed: 'Zu zahlender Betrag:',
      },
      subtitle:
        'Keine Sorge, nach Bestätigung des Gesamtbetrags werden deine verfügbaren Gutscheine automatisch berücksichtigt.',
      confirm: 'Bezahlen',
    },
    donation: {
      title: {
        custom: 'Deine Spende an {{title}}',
        fixed: 'Dein Spendenbetrag:',
      },
      subtitle: {
        custom:
          'Gib dazu einfach den gewünschten Betrag ein und bestätige diesen im nächsten Schritt. {{title}} erhält für deine Spende umgehend eine Benachrichtigung.',
        fixed:
          'Keine Sorge, nachdem du deine Spende bestätigt hast, erhält die {{title}} sofort eine Benachrichtigung, um über deine Spende informiert zu werden."',
      },

      confirm: 'Spenden',
    },
  },
  success: {
    payment: {
      title: 'Zahlung erfolgreich',
      description: 'Zeige dem Händler diese Zahlungsbestätigung',
      close: 'Schließen',
    },
    donation: {
      title: 'Spende erfolgreich',
      description: '{{name}} sagt danke!',
      close: 'Schließen',
    },
  },
};

export default de;
