const en = {
  close: 'Close',
  update: {
    title: 'Update your Bluecode App',
    description:
      'In order to continue, please update your Bluecode app to the latest version via the App Store or Google Play Store. Once updated, re-scan the QR code and follow the payment steps.',
  },
  cancel: {
    title: 'Payment canceled',
    description: 'The payment has not been made.',
  },
  error: {
    title: 'Unfortunately, an error has occurred.',
    description:
      'Please try again. Tap "Close" at the bottom and scan the QR code again.',
  },
  header: {
    subtitle: { payment: 'QR Code Payment', donation: 'QR Code Donation' },
  },
  extraItems: {
    title: 'Or pick one from this list',
  },
  'no-bank-account': {
    title: 'No connected bank account',
    description:
      'Simply connect your bank account in a few steps to pay with Bluecode.',
    button: 'Connect bank account',
  },
  tip: {
    total: 'Billed amount: {{amount}}',
    custom: {
      title: 'Enter the rounded up amount',
      input: 'New total amount',
      confirm: 'Pay',
      errors: {
        amount: 'Please enter the total amount including tips.',
      },
    },
    percentage: {
      title: 'Would you like to tip?',
      items: {
        title: 'Yes round up to:',
      },
      custom: 'Enter custom amount',
      cancel: "I don't want to tip",
      confirm: 'Pay {{amount}}',
    },
  },
  new: {
    input: 'Enter amount',
    payment: {
      title: {
        custom: 'Enter the billed amount',
        fixed: 'Amount to be paid:',
      },
      subtitle:
        "Don't worry, after confirming the total amount, your available vouchers will automatically be taken into account.",
      confirm: 'Pay',
    },
    donation: {
      title: {
        custom: 'Your donation to {{title}}',
        fixed: 'Your donation amount:',
      },
      subtitle: {
        custom:
          'Please enter the desired amount and confirm it at the next step. {{title}} will instantly receive a notification of your donation.',
        fixed:
          "Don't worry, after you confirm your donation, the {{title}} will immediately receive a notification to be notified of your donation.",
      },

      confirm: 'Donate',
    },
  },
  success: {
    payment: {
      title: 'Payment successful',
      description: 'Show the merchant this payment confirmation',
      close: 'Close',
    },
    donation: {
      title: 'Donation successful',
      description: '{{name}} says thanks!',
      close: 'Close',
    },
  },
};

export default en;
